import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`La représentation des nombres`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "225px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAEDBAL/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB1xVCbVY1wL2D/8QAGxAAAwACAwAAAAAAAAAAAAAAAAITAREDECH/2gAIAQEAAQUCqxVxHfJ50m9RQjxk1P/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABsQAAMAAgMAAAAAAAAAAAAAAAABMhBBIUKB/9oACAEBAAY/ArLRpmjqzhJ+kk4//8QAIBAAAgIBAwUAAAAAAAAAAAAAAAERMSFBkfFxgaHB8P/aAAgBAQABPyGVx4cdB54ZWRM4utstE2e8cD1jQznuKzWFzWh7Vn0YlUnuz//aAAwDAQACAAMAAAAQJx88/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHxABAQACAgIDAQAAAAAAAAAAAREAITFBUXFhgaHw/9oACAEBAAE/EJDJVHTmdeCwd7cAAkojcKIk1vE9CcmOwTg4iZUFdROfReBdPbkQWFAKTe3dX+mIoselTRepvAejthTt+8Xuu7Wt/uW5LL8QHnwGf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "chiffres",
          "title": "chiffres",
          "src": "/static/b85ed83aa04b75f293eab117df7cb44f/863e1/chiffres.jpg",
          "srcSet": ["/static/b85ed83aa04b75f293eab117df7cb44f/d2f63/chiffres.jpg 163w", "/static/b85ed83aa04b75f293eab117df7cb44f/863e1/chiffres.jpg 225w"],
          "sizes": "(max-width: 225px) 100vw, 225px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Il est possible de représenter des grandeurs par des longueurs, des intervalles, des couleurs, ... et aussi par des chiffres. Après tout les nombres `}<strong parentName="p">{`SONT`}</strong>{` des suites de chiffres.`}</p>
    <p>{`Mais la pratique observée est très hétérogène et souvent inappropriée au minimum. Sur Youtube, les sondages de la presse, à l'éducation nationale, ou dans l'entreprise le nombre de chiffres utilisés pour présenter les nombres semble être plus dû au hasard ou à l'habitude, que le fruit d'une réflexion sur la signification de ces décimales ou sur l'efficacité de la communication. `}</p>
    <center>
      <p><strong parentName="p">{`Despacito nombre de vues en milliard avec 10 chiffres (vous pouvez rafraichir la page pour avoir la valeur la plus récente)*`}</strong></p>
      <iframe width="560" height="400" src="/sandbox/chiffres.html" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen>
      </iframe>
    </center>
    <p>{`D'autres exemples de nombres pathologiques:`}</p>
    <ul>
      <li parentName="ul">{`54,18% des français aiment les chiffres contre 43,92% qui les détestent. `}</li>
      <li parentName="ul">{`la multiplication par 2,0238 du Chiffre d'affaires est remarquable...`}</li>
      <li parentName="ul">{`la longueur de la batte de baseball de 31 pouces est de 78,74 cm`}</li>
      <li parentName="ul">{`... `}</li>
    </ul>
    <p>{`Trop de chiffres ne sont pas seulement `}<strong parentName="p">{`inutiles et ridicules`}</strong>{` au regard de la précision/incertitude de la mesure.`}<br parentName="p"></br>{`
`}{`Ils sont aussi `}<strong parentName="p">{`nuisibles à:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`la perception`}</strong>{` `}</li>
      <li parentName="ul"><strong parentName="li">{`la compréhension`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`la mémorisation`}</strong></li>
    </ul>
    <h2>{`Le nombre est un diagramme en barre`}</h2>
    <p>{`Avez vous remarqué que la discipline universelle de `}<strong parentName="p">{`cadrage à droite`}</strong>{` des nombres revient à créer un diagramme ?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "387px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.239263803680984%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAAB7ElEQVQoz2WS7WtScRTH75/T/9DbIoKeVkGx6kUQ9CKiqBU9t1rEiBVRUC9qSDHGVi/1la42RZ05rle914er2TbNnKnowOVDVz/97jVzri8czoHz48P3/M6REOp2u2aiWqtRE5HJZIhEIqTSX+n3+2+215XNFtdeq4y9Urn4PMwb+ypSv2nqZ6lMPp/H5/Wy4HLxeXFpCNJ/axi9nC1uMXI7wKFby+y54uW+LY5kNur1OslUmnQqRTb3XWSdJbfbcvmXaMGMTg8k61WO3Q1w8kGQ0YkVzk7KnBgPMjWXGgBj8TiKorC2niWl64REHY2qDAbo/nOmfdvk8suINeqlFxFOP1ph5E6AyVm9B9wpE+xyOYVLz3//tlNbDYNTAnjghp/HM0mkZrNJs9mi0WhY0W63icU0ZDkkcpztSyuUG/jUMoFYBU+kxLJWwe4rcO5JyBr/6XwaKZmIE9NU3k5PY7c7iKqacBhibv6j2HLGArV/G1aeXcix64yL3Rfc1hLM2Dfm5fi9L+y/7mPiXQKp1WpZrtbXVlHCYarVGpqqEpJlkkl9yGGu+ItFpYTdX+D8lGI568fowyDPPqQHZ1Ms/MD2foaN4gYOhwOPx4PT+YmO0RmCmiqL+zsqtnzwpp/D4mSOiNPZe9XLuC3BH75RcP1kt1LKAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "chiffresbarres",
          "title": "chiffresbarres",
          "src": "/static/39d4d261301616cacfdaaac9eed3c655/691c3/chiffresbarres.png",
          "srcSet": ["/static/39d4d261301616cacfdaaac9eed3c655/222b7/chiffresbarres.png 163w", "/static/39d4d261301616cacfdaaac9eed3c655/ff46a/chiffresbarres.png 325w", "/static/39d4d261301616cacfdaaac9eed3c655/691c3/chiffresbarres.png 387w"],
          "sizes": "(max-width: 387px) 100vw, 387px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`L'alignement sur les virgules des nombres disposés verticalement fait apparaitre un diagramme grâce à la variation de l'occupation de l'espace sur la gauche. L’œil fait aisément le tri entre les `}<strong parentName="p">{`grands`}</strong>{` nombres qui se signalent par une forte déviation à gauche, des `}<strong parentName="p">{`petits`}</strong>{` qui restent misérablement sur la droite.`}</p>
    <p>{`Les spécialistes auront déjà `}<strong parentName="p">{`deviné`}</strong>{` que l'échelle est logarithmique. Un espace de plus à gauche vaut X 10. Un de moins vaut /10. Il est vrai que la technique est moins performante pour les nombres plus petit que 1, il y a alors beaucoup de zéros qui perturbent la vision. Les signes négatifs sont aussi un problème. Mais avec un peu d'entrainement ou un changement d'échelle draconien, le principe reste utilisable. `}</p>
    <p>{`Face à une liste de nombres alignés verticalement, l’œil est capable sans effort de comparer les éléments entre eux et de détecter les points saillants. Le plus grand, le plus petit. Le plus petit d'une série, etc ...`}</p>
    <h2>{`Combien de chiffres significatifs peuvent rentrer dans le cerveau ?`}</h2>
    <p>{`A moins d'être utile `}<strong parentName="p">{`individuellement`}</strong>{` comme référence précise de la suite d'un calcul (mesure d'une pièce mécanique, solde de compte en banque, ...), le nombre présenté au sein d'une `}<strong parentName="p">{`collectivité`}</strong>{` est généralement utile pour fournir un ordre de grandeur, une comparaison, indiquer un ordonnancement, ... `}</p>
    <p>{`Commençons par essayer de tester la `}<strong parentName="p">{`digestibilité`}</strong>{` des chiffres par l’œil. L’œil est capable de distinguer/comparer/ordonner sans forcer une petite centaine de `}<strong parentName="p">{`positions`}</strong>{`. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "119.01840490797547%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAYABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAH3NnoYCaAD/8QAGBAAAgMAAAAAAAAAAAAAAAAAEBEhIkL/2gAIAQEAAQUCdkNBSP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABwQAAEEAwEAAAAAAAAAAAAAAAEAEBEhMUFRcf/aAAgBAQABPyGxDUIBxjl45K6lv//aAAwDAQACAAMAAAAQgwgA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHRAAAgICAwEAAAAAAAAAAAAAAREAQSExEFFhsf/aAAgBAQABPxAEwCNiEAHDqMi5TsBr3g7jFWEPs2SLhZoz/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "dotplot",
          "title": "dotplot",
          "src": "/static/44b8bf44f3e2c24991abd287bcfea067/6aca1/dotplot.jpg",
          "srcSet": ["/static/44b8bf44f3e2c24991abd287bcfea067/d2f63/dotplot.jpg 163w", "/static/44b8bf44f3e2c24991abd287bcfea067/c989d/dotplot.jpg 325w", "/static/44b8bf44f3e2c24991abd287bcfea067/6aca1/dotplot.jpg 650w", "/static/44b8bf44f3e2c24991abd287bcfea067/065ac/dotplot.jpg 656w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Une vingtaine de `}<strong parentName="p">{`longueurs`}</strong>{` peuvent être appréciées à condition de les maintenir parallèles. L’œil compare les longueurs en comparant indirectement les positions. Si on les décale trop ou si on les fait pivoter, c'est vraiment beaucoup beaucoup plus dur.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "503px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.987730061349694%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACAUlEQVQoz1VSSW/TUBj0j4UDEkKAhDggBIIDqKiASmmhUFALVEKIRW2R2C4glkO6ZKvtLLaTOrETYuLdiWPHw/P2wO9i+/O8+Wbm+xhtpGE2C5AfZxJg/X0HazsShuNpUpvPo+Q58TyMNI1i9xs6Vt+J2PzQxW89xTIsy8L3ZxQUhhH2mzruvxVge2mjKEoJbcvEWNcp9rCl4/TdMk4tHuJ7NW3EdDodBEGYkmVKqoKBZx+P6cV5Rug6NkRJIrh58u1NQ1zf5HH2XhWLL1vQbR+MJIqUML+49LqNq085bP9QICgOJbYtC7U6W1Dd6tm4+aKBEwsHWCH2GUEUKCGBISCWm7KN26Tj+eUqJNUtELYFMcs1VVluG7iwWsPjXQnfKhoYVVWpsryr+meSWFjYahbqjm1DlnuFWokfY/mNgN7ISzNUFCWZYg7oDlxcecLizFIFOz/VwpQd24I6GBRqUz/E1hcZdclMCXmeJ0UfUWbr894QD7fFRCHXtQrDii1zHJ+8B2FquUIs5/nFKpljWabdcuvx2f2l4s6rFhRtQv/Ha9Pr9/9lStYqzvvG8wbOkbxXyKoxhmnQTHLbfdLp8jqLiw/q2CBLi0y/57ro/0dYE02yDWyCPXnrACVuDMY0TWopV/KpNMS1DQ5HWS55PQxDGIZZGEp8vpZHuPToKCH8CxjDLVuyqynCAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Compare20bars",
          "title": "Compare20bars",
          "src": "/static/6939c37cc6f02da5a13ebad2e07bfce3/a4078/Compare20bars.png",
          "srcSet": ["/static/6939c37cc6f02da5a13ebad2e07bfce3/222b7/Compare20bars.png 163w", "/static/6939c37cc6f02da5a13ebad2e07bfce3/ff46a/Compare20bars.png 325w", "/static/6939c37cc6f02da5a13ebad2e07bfce3/a4078/Compare20bars.png 503w"],
          "sizes": "(max-width: 503px) 100vw, 503px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`L’œil peut au maximum distinguer cent valeurs distinctes. Mettre plus de deux chiffres significatifs (100 valeurs possibles) à une collection de nombres est nuisible à la perception, compréhension, mémorisation.`}</strong></p>
    <h2>{`La formule qui formate à N chiffres significatifs`}</h2>
    <p>{`Il fut un temps sans électronique où la discipline de la règle à calcul et des tables logarithmes `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Table_de_logarithmes"
      }}>{`wtf?`}</a>{` enseignaient les notions de chiffres significatifs et des ordres de grandeurs. Je suis aussi suffisamment expérimenté en langages de programmation de tout genre, pour m'étonner du précipice entre les deux.`}</p>
    <p>{`Entendons nous bien, tous les langages sérieux font leurs calculs en virgule flottante et sont tous capables d'afficher un nombre dans le format que vous voulez. Nombre de chiffres en tout, avant et après la virgule, arrondi ou pas, séparateur des milliers, unités, ... Vous choisissez. Mais rien de simple pour formater en `}<strong parentName="p">{`nombre de chiffres significatifs`}</strong>{`.`}</p>
    <p>{`Essayez par exemple d'afficher dans un même calcul/format les nombres: `}{`[1264  12,39  0,1281]`}{` à 2 chiffres significatifs avec Excel ou votre langage de programmation favori. Pour obtenir `}{`[1300 12 0,13]`}{` ou 3 chiffres significatifs `}{`[1260 12,4 0,128]`}</p>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Significant_figures"
      }}>{`Wikipedia en parle`}</a></p>
    <p>{`Prenons Excel par exemple, pour fixer le nombre de chiffres significatifs, il faut combiner ARRONDI() qui tronçonne un nombre déterminé de décimales avec LOG10() qui est capable de calculer la quantité de décimales à tronquer en fonction de la grandeur du nombre.`}</p>
    <blockquote>
      <p parentName="blockquote"><code parentName="p" {...{
          "className": "language-text"
        }}>{`= ARRONDI(Valeur;NbDigits-1-ENT(LOG10(ABS(Valeur))))`}</code></p>
    </blockquote>
    <p><em parentName="p">{`Sauf pour le cas 0`}</em>{`  `}</p>
    <p>{`En javascript, une formule élégante et concise:`}</p>
    <blockquote>
      <p parentName="blockquote"><code parentName="p" {...{
          "className": "language-text"
        }}>{`= parseFloat(Valeur.toPrecision(NbDigits),10)`}</code></p>
    </blockquote>
    <p>{`En python, `}<em parentName="p">{`sauf pour 0`}</em></p>
    <blockquote>
      <p parentName="blockquote"><code parentName="p" {...{
          "className": "language-text"
        }}>{`round(num, -int(math.floor(math.log10(abs(num))) - (NbDigits - 1)))`}</code></p>
    </blockquote>
    <h2>{`Les unités`}</h2>
    <p>{`Dans la vie courante pour éliminer les zéros à gauche ou à droite de la virgule on utilise généralement des unités en multiple de milliers (3 zéros). `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`M`}</strong>{`ega 	1 000 000  `}</li>
      <li parentName="ul"><strong parentName="li">{`k`}</strong>{`ilo 			1 000  `}</li>
      <li parentName="ul"><strong parentName="li">{`u`}</strong>{`nité 	1  `}</li>
      <li parentName="ul"><strong parentName="li">{`m`}</strong>{`illi 	0,001  `}</li>
    </ul>
    <p>{`Les unités et chiffres significatifs s'entremêlent. Il est possible d'avoir au même instant les yeux sur des nombres en millions et en millièmes. Pour tous, deux chiffres significatifs sont amplement suffisants. Il faut même être capable d'ajuster l'affichage à des grandeurs qui varient entre le millième et le million !!! `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      